import { Controller } from "@hotwired/stimulus";

//Controller to show/hide the "other" price field
export default class extends Controller {
  static targets = [
    "otherCheck", //checkbox indicating whether "other" price is checked
    "otherGroup", //div input group that contains the "other" price field
  ];

  connect() {
    this.toggleOther();
  }

  toggleOther(event) {
    if (this.otherCheckTarget.checked) {
      this.otherGroupTarget.classList.remove("d-none");
    } else {
      this.otherGroupTarget.classList.add("d-none");
    }
  }
}
