import { Controller } from "@hotwired/stimulus";
import "@stripe/stripe-js";

//Sets up a Stripe Checkout redirect using a combination of the API key set in
//the metadata field and the checkout session id returned by the server via AJAX
export default class extends Controller {
  redirectToCheckout(event) {
    const stripeKey = document.querySelector('meta[name="stripe-key"]').content;
    let [session, status, xhr] = event.detail;

    let stripe = Stripe(stripeKey);
    stripe.redirectToCheckout({
      sessionId: session.id,
    });
  }
}
